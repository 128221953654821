import styled from 'styled-components'

const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  font-family: ${props => props.theme.typography.fontFamilyGT};
`

export default HeaderText
