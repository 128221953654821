import React, { useEffect, useState } from 'react'
import LoginNav from '../components/loginNav'
import SEO from '../components/seo'
import Box from '../components/box'
import HeaderText from '../components/headerText'
import Typography from '../components/typography'
import Input from '../components/input'
import Button from '../components/button'
import Helper from '../components/signupHelper'
import Footer from '../components/footer'
import checkUtmParams from '../utils/checkUtmParams'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const Login = () => {
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)

  function handleClick() {
    if (!value) {
      setError('Please input a workspace domain')
      return
    }

    if (error || !value) {
      return
    }

    window.location.replace(`https://${value}.pulse.so`)
  }

  function handleChange(e) {
    setValue(e.target.value)

    if (!e.target.value) {
      setError('Please input your workspace domain')
    } else {
      setError(null)
    }
  }

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <>
      <SEO title="Login to your account | Log in" />
      <LoginNav />
      <Box
        minHeight="70vh"
        width="100vw"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop="3rem"
      >
        <HeaderText>FIRST, LOG IN</HeaderText>
        <Typography>Continue with your Single Sign On Credentials.</Typography>

        <Box display="flex" marginTop="2rem" maxWidth="95vw" flexWrap="wrap">
          <Box display="flex" flexDirection="column">
            <Input
              placeholder="Workplace name"
              onChange={handleChange}
              value={value}
              style={{ width: '17.5rem' }}
            />
            <Helper error={true}>{error}</Helper>
          </Box>
          <Box
            height="3.5rem"
            backgroundColor="#EEEEEE"
            marginLeft=".5rem"
            padding=".875rem 1.25rem"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            pulse.so
          </Box>
        </Box>

        <Button
          colorType="primary"
          style={{ width: '15rem', marginTop: '2rem' }}
          onClick={handleClick}
        >
          Sign in With SSO →
        </Button>
      </Box>
      <Footer />
    </>
  )
}

export default Login
