import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Box from '../components/box'
import PulseLogo from '../icons/pulseLogo'
import NavLink from '../components/navLink'

const Container = styled.nav`
  height: 4rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.625rem;
  box-sizing: border-box;

  @media ${props => props.theme.device.tablet} {
    padding: 0 5rem;
  }
`

const LoginNav = () => (
  <Container>
    <Box />
    <NavLink to="/">
      <PulseLogo fill="black" />
    </NavLink>
    <Box>
      <Link
        to="/signup/"
        style={{
          color: 'black',
          backgroundColor: '#EEEEEE',
          padding: '0.625rem 0.75rem',
          borderRadius: '6.25rem',
          fontWeight: 'bold',
          textDecoration: 'none',
          fontSize: '0.875rem',
          lineHeight: '1rem',
        }}
      >
        Sign up
      </Link>
    </Box>
  </Container>
)

export default LoginNav
