import styled, { css } from 'styled-components'

const Helper = styled.span`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
  color: ${props => props.theme.core.content.tertiary};

  ${props =>
    props.error &&
    css`
      color: ${props => props.theme.foundation.negative};
    `}
`

export default Helper
